import React from "react";
import {Fade} from "react-awesome-reveal";
import "./github.scss";
import GitHubCalendar from 'react-github-calendar';
import {githubDetails} from "../../portfolio";

export default function Github() {
  const exampleTheme = {
    background: 'transparent',
    text: '#fff',
    grade4: 'hsl(272, 59%, 54%)',
    grade3: 'hsl(272, 78%, 44%)',
    grade2: 'hsl(272, 78%, 58%)',
    grade1: 'hsl(272, 78%, 72%)',
    grade0: '#eee',
  };
  if (!githubDetails.display) {
    return null;
  }
  if (githubDetails.userName) {
    return (
      <Fade bottom duration={1000} distance="20px">
        <div className="tw-main-div" id="twitter">
          <div className="timeline-Header-title">
            Github Contributions
          </div>
          <div className="centerContent">
          <GitHubCalendar username={githubDetails.userName} theme={exampleTheme} style={{"width" : 'auto', "max-width": "1400px"}}/>
          </div>
        </div>
      </Fade>
    );
  } else {
    return null;
  } 
}
